<template>
  <v-container class="payment-completed" v-if="!loading">
    <v-row no-gutters class="mt-7">
      <v-col cols="12" class="order-info">
        <v-card class="text-left default--text h-100" elevation="0">
          <div
            class="d-flex align-center flex-row flex-wrap justify-space-between"
            v-if="isError || (order && paymentFailed)"
          >
            <h1 class="font-weight-bold default--text">
              Il pagamento del tuo ordine è fallito
            </h1>
            <img
              class="mr-2"
              width="130"
              src="/img_layout/melaregalo/icon-melaregalo.png"
              alt="MelaRegalo"
            />
          </div>
          <v-card-text
            v-if="isError || (order && paymentFailed)"
            class="px-0 mt-3"
          >
            <div class="description">
              Siamo dispiaciuti ma si è verificato un errore durante il
              pagamento dell'ordine.<br />Probabilmente si è trattato di un
              problema con la tua carta di credito: ti consigliamo di verificare
              attentamente i dati che hai inserito ed effettuare un ulteriore
              tentativo oppure di contattare la tua banca.
            </div>
          </v-card-text>

          <v-card-text v-if="order && !paymentFailed">
            <div class="d-flex justify-space-between align-center">
              <h1 class="font-weight-bold default--text mb-4">
                Grazie per il tuo acquisto
              </h1>
              <img
                class="mr-2"
                width="130"
                src="/img_layout/melaregalo/icon-melaregalo.png"
                alt="MelaRegalo"
              />
            </div>
            <div class="description mt-2">
              Riceverai a breve una mail di conferma di riepilogo dell'ordine.
            </div>
          </v-card-text>

          <!-- <v-card-text v-if="!order">
            <div class="d-flex justify-space-between align-center">
              <h1 class="font-weight-bold default--text mb-4">
                Ritenta pagamento
              </h1>
              <img
                class="mr-2"
                width="130"
                src="/img_layout/melaregalo/icon-melaregalo.png"
                alt="MelaRegalo"
              />
            </div>
          </v-card-text> -->

          <MelaregaloPaymentTypeList
            v-if="showPayment"
            mode="order"
            :order-id="order.orderId"
            :options="paymentTypeListOptions"
          />

          <v-card-actions class="">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="12" sm="auto" class="px-0 px-sm-3 mb-4 mb-sm-0">
                <v-btn
                  x-large
                  depressed
                  class="text-none"
                  :color="order ? 'secondary' : 'primary'"
                  :block="$vuetify.breakpoint.xs"
                  @click="goHome"
                  >{{ $t("paymentCompleted.goToHome") }}</v-btn
                >
              </v-col>
              <!-- <v-col
                cols="12"
                sm="auto"
                v-if="paymentFailed && order.isPayable"
                class="mb-4 mb-sm-0"
              >
                <v-btn
                  v-if="!showPayment"
                  x-large
                  depressed
                  class="text-none"
                  color="primary"
                  :block="$vuetify.breakpoint.xs"
                  @click="toggleShowPayment"
                >
                  Ritenta pagamento
                </v-btn>
              </v-col> -->
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.payment-completed {
  h1 {
    font-size: 38px;
    line-height: 1;
  }
  .description,
  .reminder {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .list-order-details {
    margin-top: 40px;
    ul {
      margin: 14px 0px;
      padding-left: 40px !important;
    }
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";
import MelaregaloPaymentTypeList from "@/components/payment/MelaregaloPaymentTypeList.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentCompletedMelaregalo",
  mixins: [categoryMixins],
  components: {
    MelaregaloPaymentTypeList
  },
  data() {
    return {
      isError: false,
      loading: true,
      order: null,
      showPayment: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        prevButton: {
          type: "link-button",
          label: "payment.backToCheckout",
          class: "text-none d-none",
          options: {
            icon: "$prev",
            iconClass: "primary white--text rounded-lg mr-3",
            textClass: "primary--text text-caption font-weight-bold"
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics",
      isAuthenticated: "cart/isAuthenticated"
    }),
    paymentFailed() {
      return this.order?.orderStatusId == 2 || this.order?.orderStatusId == 11;
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics"
    }),
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    async fetchOrder(orderId) {
      let _this = this;
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!this.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
      _this.loading = false;
    },
    goHome() {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchOrder(this.$route.params.orderId);
      global.EventBus.$on("pay-response-ok", order => {
        this.showPayment = false;
        this.fetchOrder(order.orderId);
      });
    } else {
      if (this.$route.path.indexOf("error") > -1) {
        this.isError = true;
      } else if (this.$route.path.indexOf("completed") > -1) {
        this.order = this.$route.params.orderId;
        this.isError = false;
      }

      this.loading = false;
      global.EventBus.$on("pay-response-ok", () => {
        this.showPayment = false;
      });
    }
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
