<template>
  <div
    class="payment-methods mt-5 py-3 rounded-sm"
    :style="{ 'background-color': options.mainBackgroundColor || 'white' }"
  >
    <div
      v-if="!isProfileView"
      :class="
        options.titleStyle
          ? options.titleStyle
          : 'text-h3 secondary--text mb-5 font-weight-bold text--lighten-1'
      "
    >
      {{ $t("paymentTypeList.title") }}
    </div>
    <i18n path="paymentTypeList.description" tag="p" v-if="!isProfileView" />
    <v-alert v-if="isPaymentDisabled" type="error" dense>
      Per procedere al pagamento è necessario selezionare il punto di ritiro e
      la preferenza per la data di ritiro .
    </v-alert>
    <!-- 
    Per procedere al pagamento è necessario selezionare il punto vendita e la
      Data di ritiro.
       -->
    <v-row v-else justify="center" no-gutters>
      <v-expansion-panels
        accordion
        :flat="options.flatPanels"
        v-model="selectedPayment"
        :disabled="isPaymentDisabled"
      >
        <v-expansion-panel
          v-for="(paymentType, index) in paymentTypeList"
          v-bind:key="paymentType.paymentTypeId"
          :style="{
            'background-color': options.cardBackgroundColor || 'white'
          }"
        >
          <v-expansion-panel-header
            hide-actions
            v-on:click.prevent="setPaymentInfo(paymentType.name)"
          >
            <input
              type="radio"
              :id="'test' + paymentType.paymentTypeId"
              name="radio-group"
              :checked="selectedPayment === index ? true : false"
              :data-test="paymentType.gatewayClient"
            />

            <label :for="'test' + paymentType.paymentTypeId">
              <v-img
                v-if="options.showPaymentMethodImage"
                :src="`/paymentmethod/${paymentType.paymentTypeId}.svg`"
                contain=""
                eager
                onerror="this.onerror=null;this.src=''"
                height="37"
                max-width="60"
                class="mx-3 payment-method-img"
                alt="Metodo di pagamento"
              />
              {{ paymentType.name }}
            </label>
            <!-- CREDIT CARDS -->
            <v-row
              style="border-bottom: none;"
              no-gutters
              v-if="
                options.showCardLogos &&
                  [10, 12, 22].includes(paymentType.paymentTypeId) &&
                  !$vuetify.breakpoint.xs
              "
              align="center"
              :justify="options.cardsPosition || 'end'"
            >
              <v-img
                eager
                src="/credit-card/mastercard-curved-64px.png"
                alt="Mastercard"
                height="37"
                max-width="60"
                class="mr-1"
              ></v-img>
              <v-img
                eager
                src="/credit-card/electron-curved-64px.png"
                alt="Visa Electron"
                height="37"
                max-width="60"
                class="mr-1"
              ></v-img>
              <v-img
                eager
                src="/credit-card/visa-curved-64px.png"
                alt="Visa"
                height="37"
                max-width="60"
              ></v-img
            ></v-row>
            <!-- PAYPAL -->
            <v-row
              no-gutters
              v-if="
                options.showCardLogos &&
                  [13].includes(paymentType.paymentTypeId) &&
                  !$vuetify.breakpoint.xs
              "
              align="center"
              justify="end"
            >
              <v-img
                eager
                src="/credit-card/paypal-transparent-64px.png"
                alt="Paypal"
                height="37"
                max-width="90"
              ></v-img>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              :class="{
                'payment-disabled': isPaymentDisabled
              }"
            >
              <GatewayWriter
                :payment-type="paymentType"
                :order-id="orderId"
                :component-name="paymentType.gatewayClient"
                :mode="mode"
                :options="mergedOptions"
                :isProfileView="isProfileView"
                :smartCart="smartCart"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
import GatewayWriter from "~/components/payment/gateways/GatewayWriter.vue";
//import PaymentTypeService from "~/service/paymentTypeService";
import FakePaymentTypeService from "~/service/fakePaymentTypeService";
import merge from "lodash/merge";
import { mapState } from "vuex";
import AnalyticsService from "~/service/analyticsService";

export default {
  name: "MelaregaloPaymentTypeList",
  props: {
    orderId: { type: Number, required: false },
    isPaymentDisabled: { type: Boolean, default: false },
    defaultPaymentType: { type: Number, required: false, default: 0 },
    options: { type: Object, required: false, default: () => ({}) },
    autoSelectPaymentType: { type: Boolean, default: true },
    filteredPaymentTypeIds: { type: Array, required: false },
    modality: { type: String, required: false },
    isProfileView: { type: Boolean, default: false },
    paymentNameToPaymentInfo: { type: Boolean, default: false },
    needInvoice: { type: Boolean, default: false },
    disableDelayPaymentByInvoice: { type: Boolean, default: false },
    smartCart: { type: Object, required: false },
    isFisicalCard: { type: Boolean, required: true }
  },
  components: { GatewayWriter },
  data() {
    return {
      selectedPayment: null,
      paymentTypeList: []
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    mode() {
      return this.modality ? this.modality : this.orderId ? "order" : "cart";
    },
    mergedOptions() {
      return merge(
        {
          showRememberCard: true,
          showPaymentMethodImage: false,
          showCardLogos: true,
          showAddNewCardWarning: true,
          confirm: {
            show: !this.isProfileView,
            label: this.$t("paymentTypeList.confirm"),
            color: "primary",
            outline: false,
            icon: null,
            link: false,
            large: true,
            depressed: true
          },
          back: {
            show: !this.isProfileView,
            label: this.$t("common.back"),
            color: "primary",
            outline: true,
            icon: null,
            link: false,
            large: true,
            depressed: true
          }
        },
        this.options
      );
    }
  },
  methods: {
    fetchPaymentTypeList() {
      //FakePaymentTypeService.getPaymentTypeList(this.orderId, true).then(

      this.paymentTypeList = FakePaymentTypeService.getPaymentTypeList();
      console.log(this.paymentTypeList);
      this.paymentTypeList = this.paymentTypeList.data.payments;
      console.log(this.paymentTypeList);
      console.log(this.paymentTypeList);
      console.log(this.paymentTypeList);
    },
    setPaymentInfo(name) {
      AnalyticsService.checkoutOptions(2, "payment_method", name);
      AnalyticsService.addPaymentInfo(
        this.cart,
        this.paymentNameToPaymentInfo ? name : undefined
      );
    }
  },
  mounted() {
    this.fetchPaymentTypeList();
  }
};
</script>
